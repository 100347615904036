//import Navbar from './Navbar';
//import { useState,useEffect } from 'react';
import React from "react";
//import { useParams } from "react-router-dom";
//import CancelIcon from '@mui/icons-material/Cancel';
import Alert from '@mui/material/Alert';


function CancelPage() {
  //const {id} = useParams();
  //console.log(id);


  return (
    <div className="CancelPage">
        <div >
{/*        <CancelIcon color="secondary"
    sx={{display: "flex", justifyContent: "flex-end",color:"red", fontSize:"40px" }}/>*/}
          <Alert severity="error" variant="outlined"><b>Unsuccessfull Registration. Please try again!</b></Alert>

        </div>
    </div>
  );
}

export default CancelPage;
