import React, {useState, useEffect,useRef} from "react";
import { FormControlLabel, IconButton,CircularProgress,Button } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import MenuIcon from '@mui/icons-material/Menu';
import RefreshIcon from '@mui/icons-material/Refresh';

//import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import {useNavigate,Link} from 'react-router-dom'

import { blue,red } from '@material-ui/core/colors';
import { DataGrid, GridToolbar,gridClasses, useGridApiRef} from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';
//import ReactExport from "react-data-export";
import {Box} from '@mui/material/';

//import Alert from '@mui/material/Alert';
//import Paper from '@mui/material/Paper';
//import Input from '@mui/material/Input';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import AppBar from '@mui/material/AppBar';
import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';
import Fade from '@mui/material/Fade';

import CloseIcon from '@mui/icons-material/Close';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import { blueGrey } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

import Toolbar from '@mui/material/Toolbar';
import Menu from '@mui/material/Menu';
import MenuItem from '@material-ui/core/MenuItem';


import Typography from '@mui/material/Typography';
import { alpha, styled } from '@mui/material/styles';

import AuthService from "./services/auth.service";
//import AuthVerify from "./common/auth-verify";
import authHeader from "./services/auth-header";
import EventBus from "./common/EventBus";

import axios from "axios";

import moment from 'moment';
import EnrollmentGrid from './EnrollmentGrid';
import { Document, Page } from 'react-pdf';
import GetAppIcon from '@mui/icons-material/GetApp';


//import AutoSizer from "react-virtualized-auto-sizer";

/*function CustomGridToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}*/
//const color=indigo[200];



const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: theme.palette.grey[200],
    '&:hover, &.Mui-hovered': {
      backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        ODD_OPACITY + theme.palette.action.selectedOpacity,
      ),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          ODD_OPACITY +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity,
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            ODD_OPACITY + theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  },
}));

const theme = createTheme({
  palette: {
    primary: {
      main: blueGrey[50],
    },
    secondary: {
      main: '#f44336',
    },
  },
});

const ODD_OPACITY = 0.2;


const PendingPayments=() => {

  document.title = "Admin Registration Page";

  const [isLoading,setIsLoading] = useState(false);

  const [registrations,setRegistrations] = useState([]);
  const [delegateRoles,setDelegateRoles] = useState([]);

  const [confidentialList,setConfidentialList] = useState([{id:0}]);
    const [failedRegistrations,setFailedRegistrations] = useState([{id:0}]);

  const [regtoEdit,setRegtoEdit]=useState();
  const [regIdtoDel,setRegIdtoDel]=useState();

  const [pathToShow,setPathToShow]=useState();
  const [countryToShow,setcountryToShow]=useState();
  const [nameToShow,setnameToShow]=useState();
  const [lastNameToShow,setlastNameToShow]=useState();
  const [roleToShow,setroleToShow]=useState();

  const [regidToShow,setregidToShow]=useState();


  //const [regtoDel,setRegtoDel]=useState();

  //const [editId,setEditid]=useState(false);
  //const [delId,setDelid]=useState(false);

  const [open,setOpen]=useState(false);
  const [delOpen,setDelOpen]=useState(false);
  const navigate=useNavigate();

  const [currentUser, setCurrentUser] = useState(undefined);
  const [currentPage, setCurrentPage] = useState("Registrations");

  const [alertopen,setAlertOpen]=useState(false);
  const [alertmessage,setAlertMessage]=useState("");
  const [alertSeverity,setAlertSeverity]=useState('success');

  const [pageSize, setPageSize] = useState(10);


  const [openphoto,setOpenPhoto]=useState(false);
  const [scrollY,setScrollY]=useState("0");
  const [selectedFile,setSelectedFile]=useState(null);


  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    });

 const apiRef = useGridApiRef();


  // Function to extract the file extension from a path
function getFileExtension(filePath) {
  // Split the path into segments
  const pathSegments = filePath.split('/');

  // Extract the last segment, which is the file name
  const fileName = pathSegments[pathSegments.length - 1];

  // Split the file name by the dot to get the extension
  const extension = fileName.split('.').pop();

  // Return the extension
  return extension;
}



  const handlePreviewEnrollments = () => {
    //console.log('Current enrollments:', enrollments);
    // Additional logic to preview enrollments can be added here
  };

  /*appbar*/
    const [anchorEl, setAnchorEl] = React.useState(null);
    const pages = ['Registrations'/*,'Juror Assessment'*/];


    const handleChange = (event) => {
    //  setAuth(event.target.checked);
    };



    const handleMenu = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };
    /*appbar*/

    const handleExport  =async () => {
  //    console.log("Clicked");
      setIsLoading(true);
      const myHeaders = authHeader();

      const options = {
        method: 'GET',
        responseType: 'blob',
       headers:myHeaders
      };



      const API_URL = process.env.REACT_APP_BACKEND_URL+"/services/globalexcel";


    //  let res = await fetch(API_URL,options);

    fetch(API_URL,options)
    .then(response => {
      response.blob().then(blob => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = 'GlobalExcel.xlsx';
        a.click();
        setTimeout(function () {
          window.URL.revokeObjectURL(a);
        }, 200);

      });
      //window.location.href = response.url;
    })
    .then(r=>{setIsLoading(false);})
    .catch(err=>{
    EventBus.dispatch("logout");
    alert("Cannot contact server." +err);
    navigate("/admin/Login");
      setIsLoading(false);})
    ;


    };


  const handleSave =async () => {

  const myHeaders = authHeader();


  if (open) { //Save Edit Payment
  setIsLoading(true);
  const API_URL = process.env.REACT_APP_BACKEND_URL+"/services/updateRegistration";
    const options = {
      method: 'POST',
     body:JSON.stringify({
       registrationId:regtoEdit[0].registrationId,
       code:regtoEdit[0].code,organisationName:regtoEdit[0].organisationName,name: regtoEdit[0].name,
       organisationPresident:regtoEdit[0].organisationPresident,
       lastName: regtoEdit[0].lastName,country:regtoEdit[0].country,
       email:regtoEdit[0].email,phone:regtoEdit[0].phone,generalCategory:regtoEdit[0].generalCategory,
       totalFee:regtoEdit[0].totalFee,tests:regtoEdit[0].tests,pendingPayment:!regtoEdit[0].pendingPayment,paid:!regtoEdit[0].paid
     }),
     headers:myHeaders
    };
   let res = await fetch(API_URL,options);
   if (res.ok)
    {
    let responseJson = await res.json()
   .then(async s=>{
     let data;
     const API_URL1 = process.env.REACT_APP_BACKEND_URL+"/services/registrations";
     const response = axios.get(API_URL1, { headers:myHeaders}).then(response=>{
       return response.data;}).then(data=>
         {
         setRegistrations(data);setIsLoading(false);setOpen(false);
         setAlertSeverity("success");setAlertMessage("Successful editted registration with id: "+regtoEdit[0].registrationId);
         setAlertOpen(true);
       })
       .catch(err=>{
         setIsLoading(false);setOpen(false);alert("Error while contacting server. "+err);
         setAlertSeverity("error");
         setAlertMessage("Unsuccessful edit!");setAlertOpen(true);
         EventBus.dispatch("logout");navigate("/admin/Login");});
       })
    .catch(err=>{ setAlertSeverity("error");
                setAlertMessage("Unsuccessful edit!");
                setAlertOpen(true);setIsLoading(false);setOpen(false);alert("Error while editting. "+err);
                EventBus.dispatch("logout");
                navigate("/admin/Login");
              })
    }
    else {
      setAlertSeverity("error");
      setAlertMessage("Unsuccessful edit!");
      setAlertOpen(true);setIsLoading(false);setOpen(false);alert("Error while contacting server. "+res);
      EventBus.dispatch("logout");
      navigate("/admin/Login");

    }
   }
   else {//Actions to Refund/ Delete Registration
     setIsLoading(true);
     const COMP_API_URL = process.env.REACT_APP_BACKEND_URL+"/services/deleteRegistration";

     const params={registrationId:regIdtoDel};
     const queryString = new URLSearchParams(params).toString();
     //console.log(queryString);
     const options = {
       method: 'DELETE',
       headers:myHeaders
     };

     let res = await fetch(COMP_API_URL+"?"+queryString,options);
     if (res.ok)
      {
      let responseJson = await res.json().
     then(async d=>
         {//Upload Success
          // console.log("Success");


          fetchData().then(() => {
                // Perform additional actions here
                // These actions will execute regardless of whether fetchData succeeds or fails
                setIsLoading(false);setDelOpen(false);
                    setAlertSeverity("success");
                    setAlertMessage("Successful delete of registration with id " +regIdtoDel);
                    setAlertOpen(true);
                //console.log("Fetch completed");
                // Example: Call another async function or update another state
                // anotherAsyncFunction();
            })
            .catch(error => {
              setAlertSeverity("error");
                       setAlertMessage("Unsuccessful delete!");
                       setAlertOpen(true);setIsLoading(false);setDelOpen(false);alert("Error while fetching data. "+error);
                       EventBus.dispatch("logout");
                       navigate("/admin/Login");
            });



           // let data;
           // const API_URL1 = process.env.REACT_APP_BACKEND_URL+"/services/delegateregistrations";
           // const response = axios.get(API_URL1, { headers:myHeaders})
           // .then( response=>{return response.data;})
           // .then(data=>
           //     {
           //  //   console.log(data);
           //     setRegistrations(data);

           //
           //   })
           //   .catch(err=>{        setAlertSeverity("error");
           //          setAlertMessage("Unsuccessful delete!");
           //          setAlertOpen(true);setIsLoading(false);setDelOpen(false);alert("Error while contacting server. "+err);
           //          EventBus.dispatch("logout");
           //          navigate("/admin/Login");
           //        });
           })
       .catch(err=>{       setAlertSeverity("error");
              setAlertMessage("Unsuccessful delete!");
              setAlertOpen(true);setIsLoading(false);setDelOpen(false);alert("Error while deleting. "+err);
              EventBus.dispatch("logout");
              navigate("/admin/Login");
            })
          }

          else {
            setAlertSeverity("error");
            setAlertMessage("Unsuccessful delete!");
            setAlertOpen(true);setIsLoading(false);setOpen(false);alert("Error while contacting server. "+res);
            EventBus.dispatch("logout");
            navigate("/admin/Login");

          }
   }

  };


  const handleCancel = (value: string) => {
  setOpen(false);
  setDelOpen(false);
  };
  // Example mapping
const buttonToColumnName = {
"photoFilepath1":  ["firstName1", "lastName1","role1"],
"photoFilepath2":  ["firstName2", "lastName2","role2"],
"photoFilepath3":  ["firstName3", "lastName3","role3"],
"photoFilepath4":  ["firstName4", "lastName4","role4"],
"photoFilepath5":  ["firstName5", "lastName5","role5"],
"photoFilepath6":  ["firstName6", "lastName6","role6"],
"photoFilepath7":  ["firstName7", "lastName7","role7"],
"photoFilepath8":  ["firstName8", "lastName8","role8"],
"photoFilepath9":  ["firstName9", "lastName9","role9"],
"photoFilepath10":  ["firstName10", "lastName10","role10"],
"photoFilepath11":  ["firstName11", "lastName11","role11"],
"photoFilepath12":  ["firstName12", "lastName12","role12"],

// Add more mappings as needed
};



  const openPhoto = async (path,id,scrollY,buttonId) => {
  //let photourl;
  setIsLoading(true);
//  console.log('Row clicked:', buttonId);
let idAsNumber = parseInt(id, 10);


  const [rowId, buttonIndex] = buttonId.split('-');
  //console.log('Row clicked:', rowId);
  //console.log('Button clicked:', buttonIndex);

  const [nameColumn, lastNameColumn,roleColumn] = buttonToColumnName[buttonIndex];

  //console.log(nameColumn,lastNameColumn);

  const rowObject = registrations.find(row => row.registrationId === idAsNumber);

  const nameColumnValue = rowObject[nameColumn];
  const lastNameColumnValue = rowObject[lastNameColumn];
  const roleColumnValue = rowObject[roleColumn];

  const countryNameValue = rowObject['country'];

  setcountryToShow(countryNameValue);
  setnameToShow(nameColumnValue);
  setlastNameToShow(lastNameColumnValue);
  setroleToShow(roleColumnValue);

  setregidToShow(rowObject['registrationId']);

  //console.log('Column Name:', nameColumn);
  //console.log('Column Value:', nameColumnValue);
  //console.log('Column Value:', lastNameColumnValue);
  //console.log('Column Value:', countryNameValue);

  //console.log('rowObject:', rowObject);


  let myHeaders = authHeader();
  const API_URL = process.env.REACT_APP_BACKEND_URL+"/services/delegatefile?fileName="+path;
  //console.log("***",id,`${process.env.PUBLIC_URL}/pdf.worker.min.js`);
  setScrollY(scrollY);
  setPathToShow(path);

  let filteredRegistrations = registrations.filter(registration => registration.registrationId === idAsNumber);

  setRegtoEdit(registrations.filter(p=> p.registrationId===idAsNumber).map((filtered,i) =>  {return filtered}));

  const d= await axios.get(API_URL
      ,{responseType: "blob",  headers:myHeaders})
      .then(response => {
        //console.log(response.data);
        setSelectedFile(URL.createObjectURL(response.data));
      }).catch(err=>{
        EventBus.dispatch("logout");
        //alert("Cannot contact server." +err);
          navigate("/admin/Login");
          setIsLoading(false);
      //    setOpen(false);
        //  setCurrentUser(undefined);
      }).then(r =>{        //setOpen(true);
              //console.log(open);console.log(selectedFile);
              setIsLoading(false);
            //  setOpen(true);

            })
  };




  const MatEdit = ({ index }) => {
    //const apiRef = useGridApiContext();

      const handleEditClick = () => {
      //  console.log(registrations.filter(p=> p.registrationId===index).map((filtered,i) => {return filtered}));
        setRegtoEdit(registrations.filter(p=> p.registrationId===index).map((filtered,i) =>  {return filtered}));
        //setEditid(index);
        //Only not Cancelled and not Card paid
        if(!registrations.filter(p=> p.registrationId===index).map((filtered,i) => {return filtered.cancelled})[0] &&
        !registrations.filter(p=> p.registrationId===index).map((filtered,i) => {return filtered.cardPayment})[0])
          setOpen(true);
        else {setAlertOpen(true);      setAlertSeverity("warning");setAlertMessage("Nothing to edit!");}
      }
      const handleDeleteClick = () => {
          //console.log(registrations.filter(p=> p.registrationId===index).map((filtered,i) => {return filtered.cancelled})[0]);
          //setRegtoEdit(registrations.filter(p=> p.registrationId===index).map((filtered,i) =>  {return filtered.registrationId})[0]);
          setRegIdtoDel(registrations.filter(p=> p.registrationId===index).map((filtered,i) => {return filtered.registrationId})[0]);
          //setEditid(index);
          //Only not Cancelled
          if(!registrations.filter(p=> p.registrationId===index).map((filtered,i) => {return filtered.cancelled})[0])
            {setDelOpen(true);
            //  console.log(registrations.filter(p=> p.registrationId===index).map((filtered,i) => {return filtered.registrationId})[0]);
            //  console.log (registrations.filter(p=> p.registrationId===index).map((filtered,i) => {return filtered.registrationId})[0]);
            }
            else {setAlertOpen(true);      setAlertSeverity("warning");setAlertMessage("Nothing to delete!");}

      }


      return <FormControlLabel
           control={
             <Box>
               <IconButton color="secondary" aria-label="add an alarm" onClick={handleDeleteClick} >
                   <DeleteIcon style={{ color: red[500] }} />
               </IconButton>
               </Box>
           }
             />
    };

    // const flattenedRoleDetails = registrations.flatMap(({ registrationId, code,country,organisationName,cancelled, registrationEntities }) =>
    //   registrationEntities.map(detail => ({
    //     registrationId,country,organisationName,
    //     code,
    //     ...detail,
    //     role: detail.role, // Include the role in each detail
    //   }))
    // );

    // Dynamically generate columns excluding the 'id' column
    // const columns = [
    //   { field: 'registrationId', headerName: 'Registration ID', width: 150 }, // Added as the first column
    //   { field: 'code', headerName: 'Code', width: 150 }, // Added as the first column
    //   { field: 'country', headerName: 'Country', width: 150 }, // Added as the first column
    //   { field: 'organisationName', headerName: 'Organisation Name', width: 150 }, // Added as the first column
    //   { field: 'cancelled',flex: 0.3, headerName: 'Cancelled', minWidth: 80,type:'boolean'},
    //
    //   ...['firstName', 'lastName', 'email', 'role'].map(field => ({
    //     field,
    //     headerName: field.charAt(0).toUpperCase() + field.slice(1), // Capitalize the header names
    //     width: 150,
    //     editable: true, // Make columns editable if needed
    //   })),
    //   {
    //    field: 'photoFilepath',
    //    flex: 0.5,
    //    headerName: 'Photo',
    //    minWidth: 100,
    //    type: 'string',
    //    renderCell: (params: any) => (
    //    <Tooltip title={params.value}>
    //      {params.value ? (
    //        <Button
    //          variant="contained"
    //          color="primary"
    //          onClick={async() =>{
    //            console.log(selectedFile,params.value,params.id);
    //            await openPhoto(params.value,params.id,scrollY);
    //           // setOpenPhoto(true);
    //           }}
    //        >
    //          Open File
    //        </Button>
    //      ) : (
    //        <span className="table-cell-truncate">{params.value}</span>
    //      )}
    //    </Tooltip>
    //    ),
    //  },
    // ];



 // Transform flattenedRoleDetails into an array of rows
 const rows2 = registrations.map(detail => ({
   id: `${detail.registrationId}`, // Changed to use role instead of code
   ...detail,
 }));

 const firstRow = registrations[0]; // Assuming transformedData is already defined
 //const fields = Object.keys(firstRow);

//const columns2=[];
 // const columns2 = fields.map(field => ({
 //   field: field,
 //   headerName: field.charAt(0).toUpperCase() + field.slice(1), // Capitalize the first letter for better readability
 //   width: 150, // Set initial width, adjust as needed
 // }));

 // Dynamically generate columns excluding the 'id' column
 const columns2 = [
   {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width:100,
      disableClickEventBubbling: true,
      renderCell: (params) => {
          return (
              <div className="actions" style={{ cursor: "pointer" }}>
                  <MatEdit index={params.row.registrationId} />
                {/* <MatSave index={params.row.registrationId} />*/}
               </div>
          );
       }

    },
   { field: 'registrationId', headerName: 'Registration ID', width: 50 }, // Added as the first column
   { field: 'registrationDate',flex: 0.3, headerName: 'Registration Date', minWidth: 100,type:'string',
       valueGetter: (params) => (params.row.registrationDate!==""?`${moment(params.row.registrationDate).format('YYYY-MM-DD')}`:"")

       },   { field: 'code', headerName: 'Code', width: 70 }, // Added as the first column
   { field: 'country', headerName: 'Country', width: 100 }, // Added as the first column
   { field: 'organisationName', headerName: 'Organisation Name', width: 150 }, // Added as the first column
   { field: 'cancelled',flex: 0.3, headerName: 'Cancelled', width: 50,type:'boolean'},
   { field: 'role1', headerName: 'Role', width: 100 }, // Added as the first column
   { field: 'firstName1', headerName: 'First Name', width: 150 }, // Added as the first column
   { field: 'lastName1', headerName: 'Last Name', width: 150 }, // Added as the first column
   { field: 'email1', headerName: 'Email', width: 120 }, // Added as the first column
   {
    field: 'photoFilepath1',
    flex: 0.5,
    headerName: 'Photo',
    minWidth: 100,
    type: 'string',
    renderCell: (params: any) => (
    <Tooltip title={params.value}>
      {params.value ? (
        <Button
          variant="contained"
          color="primary"
          onClick={async() =>{
            const buttonId = `${params.row.id}-${params.colDef.field}`;
            //console.log(selectedFile,params.value,params.id);
            await openPhoto(params.value,params.id,scrollY,buttonId);
            setOpenPhoto(true);
           }}
        >
          Open File
        </Button>
      ) : (
        <span className="table-cell-truncate"></span>
      )}
    </Tooltip>
    ),
  },
  { field: 'role2', headerName: 'Role', width: 100 }, // Added as the first column
  { field: 'firstName2', headerName: 'First Name', width: 150 }, // Added as the first column
  { field: 'lastName2', headerName: 'Last Name', width: 150 }, // Added as the first column
  { field: 'email2', headerName: 'Email', width: 120 }, // Added as the first column
  {
   field: 'photoFilepath2',
   flex: 0.5,
   headerName: 'Photo',
   minWidth: 100,
   type: 'string',
   renderCell: (params: any) => (
   <Tooltip title={params.value}>
     {params.value ? (
       <Button
         variant="contained"
         color="primary"
         onClick={async() =>{
           const buttonId = `${params.row.id}-${params.colDef.field}`;
           //console.log(selectedFile,params.value,params.id);
           await openPhoto(params.value,params.id,scrollY,buttonId);
           setOpenPhoto(true);
          }}
       >
         Open File
       </Button>
     ) : (
       <span className="table-cell-truncate">{params.value}</span>
     )}
   </Tooltip>
   ),
 },
 { field: 'role3', headerName: 'Role', width: 100 }, // Added as the first column
 { field: 'firstName3', headerName: 'First Name', width: 150 }, // Added as the first column
 { field: 'lastName3', headerName: 'Last Name', width: 150 }, // Added as the first column
 { field: 'email3', headerName: 'Email', width: 120 }, // Added as the first column
 {
  field: 'photoFilepath3',
  flex: 0.5,
  headerName: 'Photo',
  minWidth: 100,
  type: 'string',
  renderCell: (params: any) => (
  <Tooltip title={params.value}>
    {params.value ? (
      <Button
        variant="contained"
        color="primary"
        onClick={async() =>{
          const buttonId = `${params.row.id}-${params.colDef.field}`;
          //console.log(selectedFile,params.value,params.id);
          await openPhoto(params.value,params.id,scrollY,buttonId);
          setOpenPhoto(true);
         }}
      >
        Open File
      </Button>
    ) : (
      <span className="table-cell-truncate">{params.value}</span>
    )}
  </Tooltip>
  ),
},
{ field: 'role4', headerName: 'Role', width: 150 }, // Added as the first column
{ field: 'firstName4', headerName: 'First Name', width: 150 }, // Added as the first column
{ field: 'lastName4', headerName: 'Last Name', width: 150 }, // Added as the first column
{ field: 'email4', headerName: 'Email', width: 120 }, // Added as the first column
{
 field: 'photoFilepath4',
 flex: 0.5,
 headerName: 'Photo',
 minWidth: 100,
 type: 'string',
 renderCell: (params: any) => (
 <Tooltip title={params.value}>
   {params.value ? (
     <Button
       variant="contained"
       color="primary"
       onClick={async() =>{
         const buttonId = `${params.row.id}-${params.colDef.field}`;
         //console.log(selectedFile,params.value,params.id);
         await openPhoto(params.value,params.id,scrollY,buttonId);
         setOpenPhoto(true);
        }}
     >
       Open File
     </Button>
   ) : (
     <span className="table-cell-truncate">{params.value}</span>
   )}
 </Tooltip>
 ),
},
{ field: 'role5', headerName: 'Role', width: 150 }, // Added as the first column
{ field: 'firstName5', headerName: 'First Name', width: 150 }, // Added as the first column
{ field: 'lastName5', headerName: 'Last Name', width: 150 }, // Added as the first column
{ field: 'email5', headerName: 'Email', width: 120 }, // Added as the first column
{
 field: 'photoFilepath5',
 flex: 0.5,
 headerName: 'Photo',
 minWidth: 100,
 type: 'string',
 renderCell: (params: any) => (
 <Tooltip title={params.value}>
   {params.value ? (
     <Button
       variant="contained"
       color="primary"
       onClick={async() =>{
         const buttonId = `${params.row.id}-${params.colDef.field}`;
         //console.log(selectedFile,params.value,params.id);
         await openPhoto(params.value,params.id,scrollY,buttonId);
         setOpenPhoto(true);
        }}
     >
       Open File
     </Button>
   ) : (
     <span className="table-cell-truncate">{params.value}</span>
   )}
 </Tooltip>
 ),
},
{ field: 'role6', headerName: 'Role', width: 150 }, // Added as the first column
{ field: 'firstName6', headerName: 'First Name', width: 150 }, // Added as the first column
{ field: 'lastName6', headerName: 'Last Name', width: 150 }, // Added as the first column
{ field: 'email6', headerName: 'Email', width: 120 }, // Added as the first column
{
 field: 'photoFilepath6',
 flex: 0.5,
 headerName: 'Photo',
 minWidth: 100,
 type: 'string',
 renderCell: (params: any) => (
 <Tooltip title={params.value}>
   {params.value ? (
     <Button
       variant="contained"
       color="primary"
       onClick={async() =>{
         const buttonId = `${params.row.id}-${params.colDef.field}`;
         //console.log(selectedFile,params.value,params.id);
         await openPhoto(params.value,params.id,scrollY,buttonId);
         setOpenPhoto(true);
        }}
     >
       Open File
     </Button>
   ) : (
     <span className="table-cell-truncate">{params.value}</span>
   )}
 </Tooltip>
 ),
},
{ field: 'role7', headerName: 'Role', width: 150 }, // Added as the first column
{ field: 'firstName7', headerName: 'First Name', width: 150 }, // Added as the first column
{ field: 'lastName7', headerName: 'Last Name', width: 150 }, // Added as the first column
{ field: 'email7', headerName: 'Email', width: 120 }, // Added as the first column
{
 field: 'photoFilepath7',
 flex: 0.5,
 headerName: 'Photo',
 minWidth: 100,
 type: 'string',
 renderCell: (params: any) => (
 <Tooltip title={params.value}>
   {params.value ? (
     <Button
       variant="contained"
       color="primary"
       onClick={async() =>{
         const buttonId = `${params.row.id}-${params.colDef.field}`;
         //console.log(selectedFile,params.value,params.id);
         await openPhoto(params.value,params.id,scrollY,buttonId);
         setOpenPhoto(true);
        }}
     >
       Open File
     </Button>
   ) : (
     <span className="table-cell-truncate">{params.value}</span>
   )}
 </Tooltip>
 ),
},

{ field: 'role8', headerName: 'Role', width: 150 }, // Added as the first column
{ field: 'firstName8', headerName: 'First Name', width: 150 }, // Added as the first column
{ field: 'lastName8', headerName: 'Last Name', width: 150 }, // Added as the first column
{ field: 'email8', headerName: 'Email', width: 120 }, // Added as the first column
{
 field: 'photoFilepath8',
 flex: 0.5,
 headerName: 'Photo',
 minWidth: 100,
 type: 'string',
 renderCell: (params: any) => (
 <Tooltip title={params.value}>
   {params.value ? (
     <Button
       variant="contained"
       color="primary"
       onClick={async() =>{
         const buttonId = `${params.row.id}-${params.colDef.field}`;
         //console.log(selectedFile,params.value,params.id);
         await openPhoto(params.value,params.id,scrollY,buttonId);
         setOpenPhoto(true);
        }}
     >
       Open File
     </Button>
   ) : (
     <span className="table-cell-truncate">{params.value}</span>
   )}
 </Tooltip>
 ),
},

{ field: 'role9', headerName: 'Role', width: 150 }, // Added as the first column
{ field: 'firstName9', headerName: 'First Name', width: 150 }, // Added as the first column
{ field: 'lastName9', headerName: 'Last Name', width: 150 }, // Added as the first column
{ field: 'email9', headerName: 'Email', width: 120 }, // Added as the first column
{
 field: 'photoFilepath9',
 flex: 0.5,
 headerName: 'Photo',
 minWidth: 100,
 type: 'string',
 renderCell: (params: any) => (
 <Tooltip title={params.value}>
   {params.value ? (
     <Button
       variant="contained"
       color="primary"
       onClick={async() =>{
         const buttonId = `${params.row.id}-${params.colDef.field}`;
         //console.log(selectedFile,params.value,params.id);
         await openPhoto(params.value,params.id,scrollY,buttonId);
         setOpenPhoto(true);
        }}
     >
       Open File
     </Button>
   ) : (
     <span className="table-cell-truncate">{params.value}</span>
   )}
 </Tooltip>
 ),
},

{ field: 'role10', headerName: 'Role', width: 150 }, // Added as the first column
{ field: 'firstName10', headerName: 'First Name', width: 150 }, // Added as the first column
{ field: 'lastName10', headerName: 'Last Name', width: 150 }, // Added as the first column
{ field: 'email10', headerName: 'Email', width: 120 }, // Added as the first column
{
 field: 'photoFilepath10',
 flex: 0.5,
 headerName: 'Photo',
 minWidth: 100,
 type: 'string',
 renderCell: (params: any) => (
 <Tooltip title={params.value}>
   {params.value ? (
     <Button
       variant="contained"
       color="primary"
       onClick={async() =>{
         const buttonId = `${params.row.id}-${params.colDef.field}`;
         //console.log(selectedFile,params.value,params.id);
         await openPhoto(params.value,params.id,scrollY,buttonId);
         setOpenPhoto(true);
        }}
     >
       Open File
     </Button>
   ) : (
     <span className="table-cell-truncate">{params.value}</span>
   )}
 </Tooltip>
 ),
},
{ field: 'role11', headerName: 'Role', width: 150 }, // Added as the first column
{ field: 'firstName11', headerName: 'First Name', width: 150 }, // Added as the first column
{ field: 'lastName11', headerName: 'Last Name', width: 150 }, // Added as the first column
{ field: 'email11', headerName: 'Email', width: 120 }, // Added as the first column
{
 field: 'photoFilepath11',
 flex: 0.5,
 headerName: 'Photo',
 minWidth: 100,
 type: 'string',
 renderCell: (params: any) => (
 <Tooltip title={params.value}>
   {params.value ? (
     <Button
       variant="contained"
       color="primary"
       onClick={async() =>{
         const buttonId = `${params.row.id}-${params.colDef.field}`;
         //console.log(selectedFile,params.value,params.id);
         await openPhoto(params.value,params.id,scrollY,buttonId);
         setOpenPhoto(true);
        }}
     >
       Open File
     </Button>
   ) : (
     <span className="table-cell-truncate">{params.value}</span>
   )}
 </Tooltip>
 ),
},
{ field: 'role12', headerName: 'Role', width: 150 }, // Added as the first column
{ field: 'firstName12', headerName: 'First Name', width: 150 }, // Added as the first column
{ field: 'lastName12', headerName: 'Last Name', width: 150 }, // Added as the first column
{ field: 'email12', headerName: 'Email', width: 120 }, // Added as the first column
{
 field: 'photoFilepath12',
 flex: 0.5,
 headerName: 'Photo',
 minWidth: 100,
 type: 'string',
 renderCell: (params: any) => (
 <Tooltip title={params.value}>
   {params.value ? (
     <Button
       variant="contained"
       color="primary"
       onClick={async() =>{
         const buttonId = `${params.row.id}-${params.colDef.field}`;
         //console.log(selectedFile,params.value,params.id);
         await openPhoto(params.value,params.id,scrollY,buttonId);
         setOpenPhoto(true);
        }}
     >
       Open File
     </Button>
   ) : (
     <span className="table-cell-truncate">{params.value}</span>
   )}
 </Tooltip>
 ),
},

 ];


  const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (err) {
    alert("Authentication error. " + err);
    return null;
  }
};

const fetchData = async () => {
 setIsLoading(true);
 //console.log(rows2);
 //console.log(flattenedRoleDetails);

 //console.log(flattenedRoleDetails);
// console.log(columns);
 //logDetailedRegistrationInfo(registrations);


 try {
   if (AuthService.getCurrentUser()) {
     setCurrentUser(AuthService.getCurrentUser());
     if (AuthService.getCurrentUser()) {
       const decodedJwt = parseJwt(AuthService.getCurrentUser().accessToken);

       if (decodedJwt.exp * 1000 < Date.now()) {
         EventBus.dispatch("logout");
         navigate("/admin/Login");
       }
     }
   } else {
     EventBus.dispatch("logout");
     navigate("/admin/Login");
   }

   const myHeaders = authHeader();
   const API_URL1 = process.env.REACT_APP_BACKEND_URL+"/services/delegateregistrations";
   const response = await axios.get(API_URL1,{ headers:myHeaders});
   const data = response.data;
   //console.log(response.data);
  setAlertOpen(true);
  setAlertSeverity("success");
  setAlertMessage("Data refreshed!");
  setRegistrations(data);
//console.log(registrations);

  const API_URL2 = process.env.REACT_APP_BACKEND_URL+"/services/delegateRoles";
  const response2 = await axios.get(API_URL2,{ headers:myHeaders});
  const data2 = response2.data;
  //console.log(data2);
  setDelegateRoles(data2);

 } catch (err) {
   EventBus.dispatch("logout");
   setAlertOpen(true);
   setAlertSeverity("error");
   setAlertMessage("Cannot contact server." + err);
   navigate("/admin/Login");
 } finally {
   setIsLoading(false);
 }
};

function logDetailedRegistrationInfo(registrations) {
  // Iterate over each registration in the registrations array
  registrations.forEach(registration => {
    //console.log(`Registration ID: ${registration.registrationId}`);
  //  console.log(`Code: ${registration.code}`);

     // Iterate over each roleDetail in the roleDetails array
     registration.registrationEntities.forEach(detail => {
      // console.log(detail);

     });
  });
}


  useEffect(() => {
    //console.log(checkedState);

    let data;

     //const user = AuthService.getCurrentUser();
     const myHeaders = authHeader();

     setIsLoading(true);


     if (AuthService.getCurrentUser())
     {
       //console.log( AuthService.getCurrentUser());
       setCurrentUser(AuthService.getCurrentUser());
       if (AuthService.getCurrentUser()) {
         const decodedJwt = parseJwt(AuthService.getCurrentUser().accessToken);

         if (decodedJwt.exp * 1000 < Date.now()) {
           EventBus.dispatch("logout");
           navigate("/admin/Login");
         }
       }
     }
     else {
       EventBus.dispatch("logout");
       navigate("/admin/Login");
       //setCurrentUser("");
       //window.reload();
     };

  //  if (currentPage =="Registrations")
  //  {
    // const API_URL1 = process.env.REACT_APP_BACKEND_URL+"/services/registrations";
    // const response = axios.get(API_URL1,{ headers:myHeaders}).then( response=>{
    //   //console.log(response);
    //   return response.data;}).then(data=>
    //     {
    //     console.log(data);
    //     setAlertOpen(true);
    //      setAlertSeverity("success");
    //     setAlertMessage("Data refreshed!");
    //     setRegistrations(data);
    //     setIsLoading(false);
    //
    //   //  console.log("LOAD false");
    // }).catch(err=>{
    //   EventBus.dispatch("logout");
    //   //alert("Cannot contact server." +err);
    //     navigate("/admin/Login");
    //     setIsLoading(false);
    // })

    const API_URL2 = process.env.REACT_APP_BACKEND_URL+"/services/delegateRoles";
    const response2 = axios.get(API_URL2,{ headers:myHeaders}).then( response=>{
      //console.log(response);
      return response.data;}).then(data2=>
        {
        //console.log(data2);
        setAlertOpen(true);
        setAlertSeverity("success");
        setAlertMessage("Data refreshed!");
        setDelegateRoles(data2);
        setIsLoading(false);

      //  console.log("LOAD false");
    }).catch(err=>{
      EventBus.dispatch("logout");
      //alert("Cannot contact server." +err);
        navigate("/admin/Login");
        setIsLoading(false);
    })

    const API_URL3 = process.env.REACT_APP_BACKEND_URL+"/services/delegateregistrations";
    const response3 = axios.get(API_URL3,{ headers:myHeaders}).then( response=>{
      //console.log(response);
      return response.data;}).then(data=>
        {
         //console.log(data);
         setRegistrations(data);
        // setAlertOpen(true);
        // setAlertSeverity("success");
        // setAlertMessage("Data refreshed!");
        // setDelegateRoles(data2);
        // setIsLoading(false);

      //  console.log("LOAD false");
    }).catch(err=>{
      EventBus.dispatch("logout");
      //alert("Cannot contact server." +err);
        navigate("/admin/Login");
        setIsLoading(false);
    })


},[]);


  return (


<div >
<div className="pendingpayments">
    <Dialog
      open={(open||delOpen)}
      //onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >

      {delOpen&&<DialogTitle id="alert-dialog-title">
      { "Delete registration?"}
        </DialogTitle>}
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
        Registration Id: {regIdtoDel}<br/>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} onClick={handleCancel}>No</Button>
        {open&&<Button disabled={isLoading} onClick={handleSave} autoFocus>Yes</Button>}
        {delOpen&&<Button disabled={isLoading} onClick={handleSave} autoFocus>{"Delete Registration"}</Button>}
      </DialogActions>
    </Dialog>

    {selectedFile && (
  <Dialog open={openphoto} onClose={() => setOpen(false)}  PaperProps={{ style: { maxHeight: '90vh', width: '90vw' } }}>
  <DialogTitle>
  <Typography variant="h7" component="div" sx={{ flexGrow: 1 }}>
  {"ID "+regidToShow+" "+roleToShow+":"+countryToShow+"/"+nameToShow+" "+lastNameToShow}
  </Typography>
  <Tooltip title="Close">
  <IconButton edge="end" color="inherit" onClick={() => setOpenPhoto(false)} aria-label="close">
   <CloseIcon />
  </IconButton>
  </Tooltip>
  <Tooltip title="Download">
  <IconButton
   color="primary"
   onClick={(e) => { //Download File
     e.stopPropagation();
     const link = document.createElement('a');
     link.href = selectedFile;
    // console.log(selectedFile);
  //   console.log(selectedFile);

    let ext = getFileExtension(pathToShow);

     let filename =   regidToShow+"_"+roleToShow+"_"+countryToShow+"_"+nameToShow+"_"+lastNameToShow+"."+ext;
     //filename = filename.replace(/\//g, '_');
       let modifiedfilename = filename.replaceAll(" ", "_");
     //console.log(modifiedString); // Outputs: "Your_document_title"

     link.download = modifiedfilename;
     document.body.appendChild(link);
     link.click();
     document.body.removeChild(link);
   }}
  >
   <GetAppIcon />
  </IconButton>
  </Tooltip>


  </DialogTitle>
  <DialogContent>
  {
   <img
    key={'p'}
    id="photo"
    src={selectedFile}
    alt="Selected File"
    style={{maxWidth: '100%', height: 'auto'}}
   />

}

  </DialogContent>
  </Dialog>
  )}





    {isLoading &&
    <div style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                  <CircularProgress color="secondary" />
    <span style={{ justifyContent: "center", position: "fixed"}}>Loading...please wait</span>
    </div>
    }
    </div>
<div>
{currentUser && (

  <AppBar theme={theme}
 position="static">
        <Toolbar>
          <IconButton
            size="small"
            variant="dense"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={handleMenu}

          >
            <MenuIcon />
          </IconButton>

          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
{currentPage}
          </Typography>
          <Button  disabled={isLoading} onClick={handleExport}>
          <FileDownloadIcon/>
          Excel
          </Button>

          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
           {pages.map((page) => (
             <Button
               key={page}
               disabled={isLoading}
               onClick={() => {setCurrentPage(page)}}
               sx={{ my: 2, color: 'white', display: 'block' }}
               style={{ boxShadow: page===currentPage ? '0px 3px 5px 2px rgba(0,0,0,0.3)' : '' }}
             >
               {page}
             </Button>
           ))}

         </Box>
         <Button color="primary" startIcon={<RefreshIcon />} onClick={fetchData}
disabled={isLoading} color="inherit" style={{ boxShadow: '0px 3px 5px 2px rgba(0,0,0,0.3)' }}
         >
         Refresh Data
         </Button>
         <Button disabled={isLoading} color="inherit" style={{ boxShadow: '0px 3px 5px 2px rgba(0,0,0,0.3)' }}>      <Link style={{ textDecoration: 'none', color: 'black'  }} to={"/admin/Login"}>
                  {"Logged in as "+currentUser.username}
                </Link></Button>
        </Toolbar>
      </AppBar>

)}
<Box sx={{ width: '100%' }}>
     <Fade in={alertopen} timeout={{ enter: 1000, exit: 4000 }} //Edit these two values to change the duration of transition when the element is getting appeared and disappeard
       addEndListener={() => {
         setTimeout(() => {
           setAlertOpen(false)
         }, 2000);
       }}
>
       <Alert severity={alertSeverity}
         action={
           <IconButton
             aria-label="close"
             color="inherit"
             size="small"
             onClick={() => {
               setAlertOpen(false);
             }}
           >
             <CloseIcon fontSize="inherit" />
           </IconButton>
         }
         sx={{ mb: 2 }}
       >
        {alertmessage}
       </Alert>
     </Fade>
   </Box>

           {currentPage==="Registrations"&&<Box style={{ height:  600 , width: '100%' }}
           sx={{

          '& .red': {
            //backgroundColor:  '#DCE1E5','#EF5645'
            backgroundColor:  '#EF5645',
            color: '#1a3e72',
          },
          '& .orange': {
            backgroundColor: '#FFD858',
            color: '#1a3e72',
          },
          '& .green': {
            backgroundColor: '#89E682',
            color: '#1a3e72',
          },
        }}
           >
           {/*color="#e20e14"*/}
           <Typography variant="h5"  gutterBottom component="div">

                </Typography>

           {registrations&&registrations.length>0&&<StripedDataGrid
           rowsPerPageOptions={[ 10, 25,100]}
           pageSize={pageSize}
           onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
           pagination
          loading={isLoading}
                disableExtendRowFullWidth editMode="row" sortingOrder={['asc','desc']}
                disableSelectionOnClick

                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                  setColumnVisibilityModel(newModel)
                }

           getRowClassName={(params) =>
  params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
}

           getCellClassName={(params: GridCellParams<number>) => {
             if (params.field === 'pendingPayment' && params.value === true) {
               return params.value ===true ? 'orange' : 'cold';
             }
             if (params.field === 'paid' && params.value === true) {
               return params.value ===true ? 'green' : 'orange';
             }
             if (params.field === 'cancelled' && params.value === true) {
               return params.value ===true ? 'red' : 'cold';
             }
            //  else return 'cold'
              }}

           rows={rows2} columns={columns2}
            components={{ Toolbar: GridToolbar }} experimentalFeatures={{ newEditingApi: true }}
            sx={{
                        '& .MuiDataGrid-columnHeaderTitle': {
                            textOverflow: "clip",
                            whiteSpace: "break-spaces",
                            lineHeight: 1
                        }
                    }}

            />}

     </Box>}

{isLoading &&
<div style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
              <CircularProgress color="secondary" />
<span style={{ justifyContent: "center", position: "fixed", top: "55%" }}>Loading...please wait</span>
</div>
}


</div>
</div>

  );

}

export default PendingPayments;
