import {useState,useEffect,useRef} from "react";
import React from "react";
import axios from "axios";
import { Checkbox, FormControl, FormControlLabel, FormGroup, TextField, Box } from '@mui/material';
import { CircularProgress, Button } from '@material-ui/core';
//import ButtonGroup from '@material-ui/core/ButtonGroup';
//import {Link,useHistory } from 'react-router-dom';

//import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
/**/
import CreditCardIcon from '@mui/icons-material/CreditCard';
import UploadIcon from '@mui/icons-material/Upload';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {useParams} from "react-router-dom";
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import Input from '@mui/material/Input';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Stack from '@mui/material/Stack';
//import Select from '@material-ui/core/Select';

import {  InputLabel, Select, MenuItem } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';

//import InputLabel from '@mui/material/InputLabel';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/';
import {  LocalizationProvider } from '@mui/x-date-pickers';
//import bgLocale from 'date-fns/locale/bg';
//import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
//import { createTheme, ThemeProvider } from '@mui/material/styles';
//import Moment from 'react-moment';
//import 'moment-timezone';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import InputAdornment from '@mui/material/InputAdornment';

//import CardContent from '@mui/material/CardContent';
//import CardActions from '@mui/material/CardActions';
//import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
//import MoreVertIcon from '@mui/icons-material/MoreVert';


import {useNavigate} from 'react-router-dom';
//import MuiPhoneNumber from 'mui-phone-number';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { makeStyles } from "@material-ui/core/styles";
import { useTheme, useMediaQuery } from '@mui/material';
import Typography from '@mui/material/Typography';


const useStyles = makeStyles((theme) => ({
  input: {
    background: "rgb(255, 192, 203)",
    maxLength:"4",
    size:"6"
  },
  input1: {
    background: "rgb(241, 53, 109)",
    maxLength:"4",
    size:"6"
  },
  error: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'red',
      },
      '&:hover fieldset': {
        borderColor: 'red',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'red',
      },
    },
  },

}));


const CreateRegistration=() => {
  //const {section} = useParams();
  const secondBoxRef = useRef();
  const alertRef = useRef();

  //console.log(section);
  const initialValues = {president:"",orgname:"",country:"",
      code:"",fname:"",lname:"",dateofbirth:null,email:"",phone: ""
      ,section:"Senior Ladies Hair" ,idfile:"",diplomafile:"",payLaterCode:"",payDiscountCode:""
    };

    //const roles = ['President/General Delegate', 'Ladies Delegate', 'Gents Delegate', 'Ladies Juror', 'Gents Juror', 'Aesthetics Juror', 'Nail Juror', 'Ladies Trainer'];
const [selectedRoles, setSelectedRoles] = useState(['President']); //  'General Delegate' is initially selected

const [roles,setRoles]=useState([]);

// const [roleDetails, setRoleDetails] = useState(
//   roles.reduce((acc, role) => ({ ...acc, [role]: { firstName: '', lastName: '' } }), {})
// );

const [roleDetails, setRoleDetails] = useState({
  'President': { firstName: '', lastName: '',email:'',photo:'' }
});


    // const initialValues = {president:"",orgname:"",country:"Albania",
    //     code:"",fname:"",lname:"",email:"",phone: ""
    //     ,section:section ,idfile:"",diplomafile:""
    //   };
  //Selected Tests. Used for Pricing
  const [selectedTests,setSelectedTests]=useState([]);
  //FormValues
  const [formValues,setFormValues] = useState(initialValues);
  const [formErrors,setFormErrors] = useState([]);

  //Boolean indicators of From State
  const [isSubmit,setIssubmit] = useState(false);
//Pay
  const [isPay,setIsPay] = useState(false);

  const [isCountryLoading,setIsCountryLoading] = useState(false);
  const [isLoading,setIsLoading] = useState(false);
  const [validated,setValidated] = useState(false);

  const [loadingmessage,setLoadingmessage]=useState("Loading...Please wait");

  const [registrationId,setregid]= useState(null);
  const [registrationExists,setregistrationExists]= useState(false);
  const [successUpload,setSuccessUpload] = useState(false);

  const [photofiles, setPhotoFiles] = useState([]);

  //Accepted file types for online submition
  const types = ['image/png', 'image/jpeg','image/jpg'];
  const maxfilesize="10000000";
  const [countries, setCountries] = useState({});
  //const [value, setValue] = React.useState(new Date());

  const [newCategories,setNewcategories]=useState([{}]);

  const [isChecked, setIsChecked] = useState(false);
  const [allowChecked, setAllowChecked] = useState(false);


  const [payLater, setpayLater] = useState(false);
  const [payDiscount, setpayDiscount] = useState(false);

  //code for initial opening of te form
  const [codeAccepted,setCodeAccepted] = useState(false);
  const [codeValid,setCodeValid] = useState(false);
  const [storedCode, setStoredCode] = useState('');
  const [storedCountry, setStoredCountry] = useState('');

  const successMessageRef = useRef(null);


  const [selcountrycodes,setSelcountrycodes]=useState([]);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const navigate=useNavigate();
  const classes = useStyles();

  const inputStyle = {
   borderColor: formErrors.some(error => error.name === "phone") ? 'red' : '',
  };

  const checkHandler = () => {
    setIsChecked(!isChecked)

  }

  const allowCheckHandler = () => {
    setAllowChecked(!allowChecked)

  }


/*Dialog for Code*/
  const [open, setOpen] = React.useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);


   const handleConfirm = async (e) => {

     if(!isSubmit) { //Submit only once!
     setIssubmit(true);


     setIsLoading(true);

     setLoadingmessage("Commiting Registration...Please wait...");
     const API_URL = process.env.REACT_APP_BACKEND_URL+"/services/registrationwrite";

     const myHeaders = new Headers({
       'Content-Type': 'text/plain',
     });

     const roleDetailsWithoutPhoto = Object.fromEntries(
      Object.entries(roleDetails).map(([role, details]) => {
        const { photo, ...rest } = details;
        return [role, rest];
      })
    );

    const roleDetailsArray = Object.entries(roleDetails).map(([role, details]) => ({
      role: role,
      firstName: details.firstName,
      lastName: details.lastName,
      email: details.email,
    }));

    const payload = {
      code: storedCode,
      roleDetails: roleDetailsArray,
    };

    const options = {
          method: 'POST',
          body: JSON.stringify(payload),
          headers: myHeaders,
        };

     //console.log(payload);

     try {
       let res = await fetch(API_URL, options);
       if (res.ok) {
         //console.log('Form submitted successfully');

         // Parse the response to JSON to access the returned ID
         const responseData = await res.json();
         setregid( responseData); // Assuming the ID is named 'id' in the response

         //console.log('Returned ID:', responseData);
         //console.log(responseData);

         const uploadPromises = Object.entries(roleDetails).filter(([_, details]) => details.photo).map(([role, details]) =>
         uploadFile(details.photo, role + "." + details.photo.name.split('.').pop(), responseData + "/" + role)
         .then(result => {
               if (!result) {
                   //console.log(`Upload failed for ${role}. Result: ${result}`);
                   throw new Error(`Upload failed for ${role}.`); // Throw an error if the upload fails
               } else {
                   //console.log(`Upload succeeded for ${role}.`);
               }
           })
           .catch(error => {
              console.error(`Error uploading ${role}:`, error);
              throw error; // Rethrow the error to ensure it's caught by Promise.all()
            })
           );

           await Promise.all(uploadPromises)
           .then(async() => {
            // console.log('All uploads completed successfully. Performing additional actions...');
             // Additional actions here

             try {
               // Perform additional actions after all uploads are done
              // console.log('All uploads completed successfully. Performing additional actions...');
               // Place your additional actions here
               setLoadingmessage("Commiting Registration...Please wait...");
               const COMP_API_URL = process.env.REACT_APP_BACKEND_URL+"/services/uploadedPhotos";

               const myHeaders = new Headers();
               myHeaders.append('Content-Type', 'plain/text');
               let ok = 'true';
               const params={registrationId:responseData,successfulUpload:ok};
               const queryString = new URLSearchParams(params).toString();
               //console.log(queryString);

               let res2 = await fetch(COMP_API_URL+"?"+queryString,     {
                      method: 'post',
                    })
                 .then(response => response.json())
                 .then(data =>
                   {//Upload Success
                     setSuccessUpload(true);
                     setIsLoading(false);
                     setOpenConfirm(false); // Close the dialog after confirmation
                    successMessageRef.current.scrollIntoView({ behavior: 'smooth' });


                   })
                 .catch(err=>{
                   setIsLoading(false);
                   alert("Error while uploading files. Check your connection and submit your registration again. " + err );
                 })

              } catch (error) {
                console.error(`Error in additional actions: ${error}`);
                navigate("/CancelPage");
              }

           })
           .catch(error => {
             console.error(`Error uploading photos: ${error}`);
             alert("Error while uploading files. Check your connection and submit your registration again. " + error );
             navigate("/CancelPage");
           });



       } else {
         console.error('Form submission failed');
         setIsLoading(false);
         alert("Error while uploading files. Check your connection and submit your registration again. " );
         navigate("/CancelPage");
         // Handle submission failure (e.g., show an error message)
       }
     } catch (error) {
       console.error('Error submitting form:', error);
       setIsLoading(false);
       alert("Error while uploading files. Check your connection and submit your registration again. " + error );
       navigate("/CancelPage");
       // Handle network or other errors
     }
     setOpenConfirm(false); // Close the dialog after confirmation
     setIsLoading(false);
   }

   };



   const handleClose = () => {
     setOpenConfirm(false); // Close the dialog without confirming
   };

  const handleClickOpen = async () => {
    setIsLoading(true);


    const ORG_API_URL = process.env.REACT_APP_BACKEND_URL+"/services/organisation";

    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'plain/text');

    const params={code:formValues.code};
    const queryString = new URLSearchParams(params).toString();
    //console.log(queryString);

    let res = await fetch(ORG_API_URL+"?"+queryString).then(async response=>{
    if(!response.ok){//Error on server
          setFormValues({ ...formValues,code:"",president:"",orgname:"",country:""});
          alert('Error contacting server.Check your connection and try again later. Error fetching code: '+response);
          //console.log(res);
          setIsLoading(false);
    }
    else { //fetch ok
      let res1 = await response.json()
      .then(data=> {setFormValues({ ...formValues,president:data.president,orgname:data.name,country:data.country});
                          setIsLoading(false);setOpen(true);setStoredCountry(data.country);
                          //console.log(data.delegateRoles);
                          //console.log(data.registrationExists);
                          setregistrationExists(data.registrationExists);

                          setRoles(data.delegateRoles);
                        //  setSelcountrycodes(countries.filter(country=> country.name===data.country).map(filt=>{return filt.iso2Code.toLowerCase()})[0]);
                        })
      //Code does not exist, fetch returns null
      .catch(err=>{//Code is Not Valid
        setFormValues({ ...formValues,code:"",president:"",orgname:"",country:""});
        //alert('Error contacting server.Check your connection and try again later. Error fetching code: '+err);
      setIsLoading(false);setOpen(true);});
    }
    })
    .catch (err=>{alert('Error contacting server.Check your connection and try again later.'+err);setIsLoading(false);})

    //process.env.REACT_APP_BACKEND_URL+"/services/registration?regid="+registrationId
  };


  const handleCloseYes = (value: string) => {
    //Disselect tests so price is recalculated
    //checkedState.fill(false);
    //Empty photofiles. Only one category is alllowed
  //  setPhotoFiles([]);
    setOpen(false);
    setCodeAccepted(true);
    setCodeValid(true);
    setStoredCode(formValues.code);

    // window.scrollTo({
    //   top: secondBoxRef.current.offsetTop+10,
    //   behavior: 'smooth'});

  };
  const handleCloseNo = (value: string) => {
    setOpen(false);
    setCodeAccepted(false);
    setCodeValid(false);
    //checkedState.fill(false);
    //Empty photofiles. Only one category is alllowed
  //  setPhotoFiles([]);
    //setTotalPrice(0);
    //setFormValues({ ...formValues,code:"",president:"",orgname:"",country:""});
    setStoredCode("");
    setRoleDetails({
  'President': { firstName: '', lastName: '', email: '', photo: '' }
});
setRoles([]);


  };

  const handleCloseDis = (value: string) => {
    setCodeAccepted(false);
    setCodeValid(true);
  //  checkedState.fill(false);
    //Empty photofiles. Only one category is alllowed
  //  setPhotoFiles([]);
  //  setTotalPrice(0);
  //  setFormValues({ ...formValues,code:"",president:"",orgname:"",country:""});
  setStoredCode("");
  setRoleDetails({
'President': { firstName: '', lastName: '', email: '', photo: '' }
});
setOpen(false);
  };

  useEffect(async () => {
    //console.log(checkedState);
    let data;
    setIsCountryLoading(true);

    const API_URL1 = process.env.REACT_APP_BACKEND_URL+"/services/countries";
    const response = await axios.get(API_URL1, data).then( response=>{
    //console.log(response);
      return response.data;}).then(data=>
        {
        setCountries(data);
        //console.log(data);
    }).catch(err=>{
      //console.log(err);
      alert("Error contacting server.Check your connection and try again later. " + err );

      //console.log(err);
    })

  //   const API_URL2 = process.env.REACT_APP_BACKEND_URL+"/services/generalCategories";
  //   const responsecat = await axios.get(API_URL2, data).then( responsecat=>{
  //     return responsecat.data;}).then(datacat=>
  //       {//Use Params SEction is ok
  //         //console.log(datacat);
  //
  //     //    if (datacat.map(m =>{return m.name}).indexOf(section)===-1){
  //     //      navigate("/NotFoundPage");
  //     //    }
  //     //    else
  //         setNewcategories(datacat);
  //         console.log(datacat);
  //
  //   }
  // ).catch(err=>{
  //       alert("Error contacting server.Check your connection and try again later. " + err);
  //     })
       setIsCountryLoading(false);


      //console.log(isLoading+" "+isCountryLoading);
   },[formErrors]);


const uploadFile = async (filetoup,filename,dirname) => {

    let res;
    setLoadingmessage("Uploading file: "+filename+ " for test "+dirname);
    const data = new FormData();
    data.append("file", filetoup);
    data.append("filename",filename.toLowerCase());
    data.append("dirname",dirname);

    //console.log(formValues.idfile);
    const F_URL = process.env.REACT_APP_BACKEND_URL+"/services/files";
    //console.log(F_URL);
    try{
      res = await fetch(
      F_URL,
        {
          method: 'put',
          body: data,
          // headers: {}
        })
      //console.log(res);
    } catch (err) {
        //console.log(error);
        alert("Error while uploading files. Check your connection and submit your registration again. " + err );
        return false;
      }
      return res.ok;
  }

//Insert Code input
const codeChange = (e) =>{
  const { name, value } = e.target;
  setFormValues({ ...formValues,[name]: value});
}
//Function to "hear" Enter key button on code insert
const handleKeypress = (e) =>{
//console.log(e.key);
   if (e.key === "Enter" ) {
     document.getElementById("codebutton").click();
   }
 }

 const handleChange = (field, role) => (e) => {
  // console.log(e.target);


   if (field ==='firstName'|| field ==='lastName'){
     setRoleDetails((prevState) => ({
       ...prevState,
       [role]: { ...prevState[role], [field]: e.target.value.toUpperCase() },
     }));
   }
   else{
   setRoleDetails((prevState) => ({
     ...prevState,
     [role]: { ...prevState[role], [field]: e.target.value },
   }));
 }
  // console.log(roleDetails[role]);
 };


  const onSubmit = (e) => {
  const errors = validateSelectedRoles( roleDetails);

  //console.log(formErrors);

  if (errors.length > 0) {
    setFormErrors(errors);
  } else {
    setFormErrors([]);
    //console.log("Form is valid");
    setOpenConfirm(true);
    //e.preventDefault();
    // Proceed with form submission logic
  }
};


  function validateSelectedRoles(roleDetails) {
  let errors = [];

  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

  for (let role in roleDetails) {
    if (roleDetails[role]) {
      if (!roleDetails[role].firstName) {
        errors.push({ role, field: 'firstName', message: `${role}: First Name is missing` });
      }
      if (!roleDetails[role].lastName) {
        errors.push({ role, field: 'lastName', message: `${role}: Last Name is missing` });
      }
      if (!roleDetails[role].email) {
        errors.push({ role, field: 'email', message: `${role}: Email is missing` });
      } else if (!emailRegex.test(roleDetails[role].email)) {
        errors.push({ role, field: 'email', message: `${role}: Invalid Email Format` });
      }
      if (!roleDetails[role].photo) {
        errors.push({ role, field: 'photo', message: `${role}: Photo is missing` });
      }
    }
  }

  return errors;
}


  function hasErrorForRoleAndField(role, field) {
  return formErrors.some(error => error.startsWith(`${role}: ${field}`));
}

const handleFileChange = (e, role) => {
  const file = e.target.files[0];
  const maxSize = 5 * 1024 * 1024; // Maximum file size in bytes (5MB)

  // Check if the file size exceeds the maximum allowed size
  if (file&&file.size > maxSize) {
    alert('File too large, please upload file less than '+maxfilesize/1000000+'MB');
    e.target.value = ''; // Reset the input value
    setRoleDetails(prevState => ({
      ...prevState,
      [role]: { ...prevState[role], photo: '' }, // Reset the photo to an empty string
    }));
    return; // Exit the function early
  }

  // Proceed with your existing validation logic
  if (file&&isValidFile(file)) {
    setRoleDetails(prevState => ({
      ...prevState,
      [role]: { ...prevState[role], photo: file },
    }));
  } else   if (file&&!isValidFile(file))

  {
    // Handle the case where the file is not valid
    // Assuming formErrors is managed outside this component or passed as a prop
    const errorIndex = formErrors.findIndex(
      err => err.role === role && err.field === 'photo'
    );

    if (errorIndex !== -1) {
      // Optionally update the error message or details here if needed

    } else {
      // Add a new error entry for the photo upload failure
      //formErrors.push({ role, field: 'photo', message: "Invalid file format. Accepted file types: " + types });
    }
    alert('Invalid file format. Accepted file types:'+ types);

    e.target.value = ''; // Reset the input value
    setRoleDetails(prevState => ({
      ...prevState,
      [role]: { ...prevState[role], photo: '' }, // Reset the photo to an empty string
    }));
  }
};


const isValidFile = (file) => {
  // Implement your validation logic here
  // For demonstration, let's say the file is invalid if it's not an image
  return types.includes(file.type)
};



  return(

      <div id="formContainer" className="create">

      <h1>Delegation Registration Form 2024</h1>

          <Alert severity="info" variant="standard" className="custom-alert">
      <strong>OMC WORLD CUP 2024</strong><br />
      PARIS OCTOBER 12-14 2024<br />
      DELEGATION REGISTRATION FORM<br />
      OMC HAIRWORLD -         <span className="red-text">MCB ENTRIES PASSES POLICY</span><br />
      FINAL REGISTRATIONS:<br />
      ENTRY PASSES PICK-UP - MERCURE HOTEL "ROOM EIFFEL 1"<br />
      FRIDAY OCTOBER 11 FROM 11:00 AM TO 4:00 PM<br />
      SATURDAY, OCTOBER 12 FROM 10AM TO 4:00 PM<br />
      OMC GLOBAL AWARD NIGHT - SATURDAY, OCT 12 AT 7:00 PM "HALL 1" MAIN STAGE<br />
      <span className="red-text">6 ENTRY TICKET PER ORGANISATION</span>
    </Alert>

        {!formValues.code&&<Alert severity="info" variant="outlined">
        <b>Please enter your organisation code in order to proceed. <br/>
         {/*<Typography sx={{textDecoration: 'underline', fontSize: '15px', color: 'red' }}>
         Contact your organisation president to provide you with the corresponding organisation code.</Typography>*/}
        </b></Alert>}


        <form id="myForm" action = {process.env.REACT_APP_BACKEND_URL+"/services/registration?regid="+registrationId} method="POST">
        <div className="code">
        <Stack direction="row" justifyContent="center" alignItems="left" spacing={6}>
        <label htmlFor= "code">Organisation code</label>
        <TextField disabled={validated||isLoading||successUpload}
        type="text" required
        style ={{width: '40%'}}
        name= "code" id= "code"
        value={formValues.code}
        onChange={codeChange}
        onKeyPress={handleKeypress}

        />
        <Button disabled={validated||isLoading||successUpload} id="codebutton" color="primary" variant="contained" onClick={handleClickOpen}  autoFocus>
        Enter Code
        </Button>
        </Stack>
      </div>

      <Dialog
        open={open}
        //onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
      {formValues.orgname===""&&<div>
      <DialogTitle id="alert-dialog-title">
        {codeValid?"":"Not a  valid code"}
      </DialogTitle>
        <Alert severity="error">{codeValid?"Please ask your president for the right information and enter proper code.":"Please enter a valid code!"}</Alert>
          <DialogActions>
            <Button onClick={handleCloseNo}
            style={{
              border: '2px solid #d9534f', // Red border for Disagree button
              backgroundColor: '#f8d7da', // Light red background
              color: '#721c24', // Darker text color for contrast
              '&:hover': { // Styles for hover state
                backgroundColor: '#ecf0f1',
                borderColor: '#d9534f',
              },
            }}
            >Close</Button>  </DialogActions></div>
      }
      {formValues.orgname!==""&&registrationExists&&<div>
      <DialogTitle id="alert-dialog-title">
        {codeValid?"":"Not a  valid code"}
      </DialogTitle>
        <Alert severity="error">{"Delegates already filled for organisation code "+formValues.code}</Alert>
          <DialogActions>
            <Button onClick={handleCloseNo}
            style={{
              border: '2px solid #d9534f', // Red border for Disagree button
              backgroundColor: '#f8d7da', // Light red background
              color: '#721c24', // Darker text color for contrast
              '&:hover': { // Styles for hover state
                backgroundColor: '#ecf0f1',
                borderColor: '#d9534f',
              },
            }}
            >Close</Button>  </DialogActions></div>
      }
        { formValues.orgname!==""&&!registrationExists&&<div>
        <DialogTitle id="alert-dialog-title">
          {"Accept organisation/country for code: "+formValues.code+" ?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {"Organisation name: "+formValues.orgname}<br />
          {"Country: " + formValues.country}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
              <Button
                onClick={handleCloseDis}
                style={{
                  border: '2px solid #d9534f', // Red border for Disagree button
                  backgroundColor: '#f8d7da', // Light red background
                  color: '#721c24', // Darker text color for contrast
                  '&:hover': { // Styles for hover state
                    backgroundColor: '#ecf0f1',
                    borderColor: '#d9534f',
                  },
                }}
              >
                Disagree
              </Button>
              <Button
                onClick={handleCloseYes}
                autoFocus
                style={{
                  border: '2px solid #5cb85c', // Green border for Agree button
                  backgroundColor: '#d9edf7', // Light green background
                  color: '#155724', // Darker text color for contrast
                  '&:hover': { // Styles for hover state
                    backgroundColor: '#cfd8dc',
                    borderColor: '#5cb85c',
                  },
                }}
              >
                Agree
              </Button>
            </DialogActions>
        </div>
      }
      </Dialog>


        {<Box ref={secondBoxRef} tabIndex="-1"
        sx={{ display: 'flex', flexDirection: 'column', gap: 2 ,border: '2px solid #ddd', padding: '10px',
        opacity: validated||isLoading ? 0.8 : 1 }} disabled={validated||isLoading} >

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 ,border: '2px solid #ddd', padding: '10px',
        opacity: 0.8 }}  className="notreqfields">

          <label htmlFor="orgname"><strong>Member country organisation name</strong></label>
          <TextField
            disabled={!codeAccepted||validated||isLoading}
            type="text"
            variant="outlined"
            required
            name="orgname" id="orgname"
            style ={{width: '100%'}}
            InputProps={{
               readOnly: true,
             }}
             sx={{
     '& .MuiFilledInput-root': {
       backgroundColor: 'rgba(128, 128, 128, 0.1)', // Grey color with 50% opacity
     },
   }}
            value={codeAccepted?formValues.orgname:""}
            onChange={handleChange}
              />

              {countries.length > 0 && (
      <FormControl size="small" fullWidth>
        <label htmlFor="country"><strong>Country</strong></label>
        <Select
          disabled={validated || isLoading ||codeAccepted}
          style={{ width: '100%' }}
          inputProps={{
            style: { textTransform: 'uppercase' },
            name: "country",
            id: "country",
            autoComplete: "off", // Explicitly set autocomplete to "off"
            value: formValues.country,
            readOnly: true,

          }}
          onChange={handleChange}
        >
          {countries.map((item) => (
            <MenuItem key={item.id} value={item.name}>
              {item.name.toUpperCase()}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )}
              </Box>

          </Box>}

      {roles&& codeAccepted&&   <fieldset disabled={!codeAccepted||validated||isLoading||successUpload}>

  <div className="notreqfields">
  {roles.map((role) => (
    <Box key ={role} sx={{ display: 'flex', flexDirection: 'column', gap: 0 ,border: '2px solid #ddd', padding: '5px',
    opacity: 1 }}  className="notreqfields">
     <FormControlLabel
        control={
          <Checkbox
          disabled = {!codeAccepted||validated||isLoading||successUpload ? true : false}

            checked={roleDetails.hasOwnProperty(role)}
            onChange={(e) => {
              if (e.target.checked) {
                if (role !== 'President') {

                setRoleDetails(prevState => ({
                  ...prevState,
                  [role]: { firstName: '', lastName: '' },
                }));

              }
              } else {
                if (role !== 'President') {

                setRoleDetails(prevState => {
                  const { [role]: _, ...rest } = prevState;
                  return rest;
                });}
              }
            }}
          />
        }
        label={
              <span style={{ fontWeight: 'bold' }}>{role}</span>
            }      />
      {roleDetails.hasOwnProperty(role) && (
        <>
          <InputLabel htmlFor={`${role}-firstName`}>First Name</InputLabel>
        <TextField disabled={validated||isLoading}
            fullWidth
            type="text"
            id={`${role}-firstName`}
            label=""
            disabled={!codeAccepted||validated||isLoading||successUpload}
            variant="outlined"
            value={roleDetails[role].firstName}
            onChange={handleChange('firstName', role)}

            InputLabelProps={{
              shrink: false,
            }}

            inputProps={{
                style: {textTransform: 'uppercase'},
            }}

            error={formErrors.some(error => error.role === role && error.field === 'firstName')}
            helperText={formErrors.find(error => error.role === role && error.field === 'firstName')?.message}


          />
          <InputLabel htmlFor={`${role}-lastName`}>Last Name</InputLabel>
          <TextField
            fullWidth
            disabled={!codeAccepted||validated||isLoading||successUpload}
            type="text"
            id={`${role}-lastName`}
            label=""
            variant="outlined"
            value={roleDetails[role].lastName}
            onChange={handleChange('lastName', role)}
            inputProps={{
                style: {textTransform: 'uppercase'},
            }}
            InputLabelProps={{
              shrink: false,
            }}
            error={formErrors.some(error => error.role === role && error.field === 'lastName')}
            helperText={formErrors.find(error => error.role === role && error.field === 'lastName')?.message}

          />
          <InputLabel htmlFor={`${role}-email`}>Email</InputLabel>
          <TextField type="email"
          id={`${role}-email`}
          label=""
          //error={formErrors.some(error => error.name === "email")}
          disabled={!codeAccepted||validated||isLoading||successUpload}
          style ={{width: '100%'}}
          value={roleDetails[role].email}
          onChange={handleChange('email', role)}
          error={formErrors.some(error => error.role === role && error.field === 'email')}
          helperText={formErrors.find(error => error.role === role && error.field === 'email')?.message}

          />
          <input style={{ display: 'none' }}
          key={`${role}-photo`} type="file"
          onChange={(e) => handleFileChange(e, role)}

          id={`${role}-photo`}
          name={`${role}-photo`}
           accept={types}/>

          <label style={{ fontWeight: 'bold' }} htmlFor={`${role}-photo`}>Photo<span className="reqfiles">*</span><br/>
             <Button variant="contained" color="secondary" size="small"   disabled={!codeAccepted||validated||isLoading||successUpload} component="span">
               {roleDetails[role].photo?"Change Selection":"Upload file"}
             </Button>
             {roleDetails[role].photo&&
             <Card sx={{ maxWidth: 350 }}>
             <CardMedia
               component="img"
               height="194"
               image={URL.createObjectURL(roleDetails[role].photo)}
               alt="Photo"
             />
           </Card>}
            </label>
            {/* Display error if there's one */}
        {formErrors.some(err => err.role === role && err.field === 'photo') && (
          <Alert severity="error" /*onClose={() => {
            // Clear the error for this role's photo upload
            const errorIndex = formErrors.findIndex(
              err => err.role === role && err.field === 'photo'
            );
            if (errorIndex !== -1) {
              formErrors.splice(errorIndex, 1); // Remove the error from the array
            }
          }}*/>
            {formErrors.find(err => err.role === role && err.field === 'photo').message}
          </Alert>
        )}

        </>
      )
    }
    </Box>
  ))}
</div>
</fieldset>}

          {formErrors.length > 0 && (
          <div >
            {/* Alert for the general message */}
            <Alert  severity="error">
              <p>Registration couldn't be submitted. Please correct errors and re-submit the form:</p>
            </Alert>

            {/* Alerts for individual errors */}
            {formErrors.map((errorObj) => (
          <Alert severity="error" key={errorObj.message}>
            {errorObj.message}
          </Alert>
        ))}
          </div>
          )}




          {successUpload&&!isLoading&&registrationId&&codeAccepted&&
            <div ref={successMessageRef}  className="SuccessPage"
             style={{
               width: "80%",
               display: "flex",
               justifyContent: "flex-end",
               padding: "0px",
               overflow: "auto" // Add this line to handle potential overflow
             }}>
            <CheckCircleIcon style={{ color:"green", fontSize:"40px" }}/>
              <Paper><span className="orangespan">Successfull Delegates Registration!<br/></span> An email was sent to President's email <b>{roleDetails['President'].email}</b>.<br />Please check your spam/junk folder also.<br />The details of your registration are:<br/>
              {Object.entries(roleDetails).map(([role, details]) => (
                <div key={role}>
                  <strong>{role}:</strong>
                  <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}> {/* Style for list */}
                    <li>First Name: {details.firstName || 'N/A'}</li>
                    <li>Last Name: {details.lastName || 'N/A'}</li>
                    <li>Email: {details.email || 'N/A'}</li>
                  </ul>
                </div>
              ))}

                <p><b>Please save this information for future reference of your registration.</b></p>

                </Paper>
            </div>}

            <Dialog open={openConfirm} onClose={handleClose}>
        <DialogTitle>Confirm Details</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please review the details below before submitting:
          </DialogContentText>
          {/* Map over roleDetails to list out the details */}
          {Object.entries(roleDetails).map(([role, details]) => (
            <div key={role}>
              <strong>{role}:</strong>
              <ul>
                <li>First Name: {details.firstName || 'N/A'}</li>
                <li>Last Name: {details.lastName || 'N/A'}</li>
                <li>Email: {details.email || 'N/A'}</li>
                <li>Photo: {details.photo ? 'Uploaded' : 'Not Uploaded'}</li>
              </ul>
            </div>
          ))}
        </DialogContent>
        <DialogActions>
           <Button
             disabled={isSubmit}
             onClick={handleClose}
             style={{
               border: '2px solid #000', // Example border color
               backgroundColor: '#f0f0f0', // Light grey background
               '&:hover': { // Styles for hover state
                 backgroundColor: '#e0e0e0',
               },
             }}
           >
             Cancel
           </Button>
           <Button
             disabled={isSubmit}
             onClick={handleConfirm}
             style={{
               border: '2px solid #007bff', // Example border color for confirm button
               backgroundColor: '#007bff', // Blue background
               color: '#fff', // White text color
               '&:hover': { // Styles for hover state
                 backgroundColor: '#0056b3',
               },
             }}
           >
             Confirm
           </Button>
         </DialogActions>
      </Dialog>

          {!isLoading&&!validated&&<div>
           <Button endIcon={!validated&&<UploadIcon/>}
            onClick = {onSubmit} variant="contained"
            disabled={successUpload||!codeAccepted}
            type="button" autoFocus >
             {!validated&&"Submit Registration"}
           </Button>
           </div>}


          {(isCountryLoading||isLoading /*&&!isPay*/) &&
          <div className="circular" style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                        <CircularProgress thickness={1} size={150}/>
          <span style={{ justifyContent: "center", position: "fixed", top: "65%" }}>{loadingmessage}</span>
          </div>
          }
        </form>
      </div>
  );
}

export default CreateRegistration;
